/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Preflight from './Preflight';

/**
 * The PreflightPaginatedResult model module.
 * @module model/PreflightPaginatedResult
 * @version V1
 */
class PreflightPaginatedResult {
    /**
     * Constructs a new <code>PreflightPaginatedResult</code>.
     * @alias module:model/PreflightPaginatedResult
     */
    constructor() { 
        
        PreflightPaginatedResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PreflightPaginatedResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PreflightPaginatedResult} obj Optional instance to populate.
     * @return {module:model/PreflightPaginatedResult} The populated <code>PreflightPaginatedResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PreflightPaginatedResult();

            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [Preflight]);
            }
            if (data.hasOwnProperty('totalCount')) {
                obj['totalCount'] = ApiClient.convertToType(data['totalCount'], 'Number');
            }
            if (data.hasOwnProperty('pageNumber')) {
                obj['pageNumber'] = ApiClient.convertToType(data['pageNumber'], 'Number');
            }
            if (data.hasOwnProperty('pageSize')) {
                obj['pageSize'] = ApiClient.convertToType(data['pageSize'], 'Number');
            }
            if (data.hasOwnProperty('totalPages')) {
                obj['totalPages'] = ApiClient.convertToType(data['totalPages'], 'Number');
            }
            if (data.hasOwnProperty('hasNextPage')) {
                obj['hasNextPage'] = ApiClient.convertToType(data['hasNextPage'], 'Boolean');
            }
            if (data.hasOwnProperty('hasPreviousPage')) {
                obj['hasPreviousPage'] = ApiClient.convertToType(data['hasPreviousPage'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PreflightPaginatedResult</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PreflightPaginatedResult</code>.
     */
    static validateJSON(data) {
        if (data['items']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['items'])) {
                throw new Error("Expected the field `items` to be an array in the JSON data but got " + data['items']);
            }
            // validate the optional field `items` (array)
            for (const item of data['items']) {
                Preflight.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Array.<module:model/Preflight>} items
 */
PreflightPaginatedResult.prototype['items'] = undefined;

/**
 * @member {Number} totalCount
 */
PreflightPaginatedResult.prototype['totalCount'] = undefined;

/**
 * @member {Number} pageNumber
 */
PreflightPaginatedResult.prototype['pageNumber'] = undefined;

/**
 * @member {Number} pageSize
 */
PreflightPaginatedResult.prototype['pageSize'] = undefined;

/**
 * @member {Number} totalPages
 */
PreflightPaginatedResult.prototype['totalPages'] = undefined;

/**
 * @member {Boolean} hasNextPage
 */
PreflightPaginatedResult.prototype['hasNextPage'] = undefined;

/**
 * @member {Boolean} hasPreviousPage
 */
PreflightPaginatedResult.prototype['hasPreviousPage'] = undefined;






export default PreflightPaginatedResult;

