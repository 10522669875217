import error from "../pages/404";

// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.

export default [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../pages/LoginPage.vue"),
  },
  {
    path: "/zapier-auth",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/AuthorizeZapierPage"),
  },
  {
    path: "/validate-account",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/ValidateAccount"),
  },
  {
    path: "/sign-up",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/RegisterAccountPage"),
  },
  {
    path: "/email-validated",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/EmailValidatedPage"),
  },
  {
    path: "/email-not-validated",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/EmailNotValidatedPage"),
  },
  {
    path: "/sign-up/appsumo",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/RegisterAccountPage"),
  },
  {
    path: "/forgot-password",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/ForgotPasswordPage"),
  },
  {
    path: "/update-password",
    component: () =>
      import(
        /* webpackChunkName: "internal" */ "../pages/UpdatePasswordViaLinkPage"
      ),
  },
  {
    path: "/accept-invite",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/AcceptInvitePage"),
  },
  {
    path: "/overview",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/Overview"),
  },
  {
    path: "/proof-archive",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/ProofArchivePage"),
  },
  {
    path: "/proof-item",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/ProofItemPage"),
  },
  {
    path: "/contact-archive",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/ApproverArchivePage"),
  },
  {
    path: "/approver-item",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/ApproverItemPage"),
    props: (route) => ({ id: route.query.id }),
  },
  {
    path: "/automation-archive",
    component: () =>
      import(
        /* webpackChunkName: "internal" */ "../pages/AutomationArchivePage"
      ),
  },
  {
    path: "/preflights",
    component: () =>
      import(
        /* webpackChunkName: "internal" */ "../pages/PreflightsPage"
      ),
  },
  {
    path: "/workflow-item",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/WorkflowItem"),
  },
  {
    path: "/template-item",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/TemplateItemPage"),
  },
  {
    path: "/profile",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/ProfilePage"),
  },
  {
    path: "/account",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/AccountPage"),
  },
  {
    path: "/users",
    component: () =>
        import(/* webpackChunkName: "internal" */ "../pages/UsersPage"),
  },
  {
    path: "/billing",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/BillingPage"),
  },
  {
    path: "/cancel",
    component: () => 
      import(/* webpackChunkName: "internal" */ "../pages/BillingPage"),
  },
  {
    path: "/create",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/CreatePage"),
  },
  {
    path: "/thanks",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/ThankYou"),
  },
  {
    path: "/success",
    component: () =>
      import(/* webpackChunkName: "internal" */ "../pages/ThankYou"),
  },
  {
    path: "/compare/*",
    component: () =>
        import(/* webpackChunkName: "external" */ "../pages/ComparePage"),
  },
  {
    path: "/review/*",
    component: () =>
        import(/* webpackChunkName: "external" */ "../pages/ReviewPage.router"),
  },
  {
    path: "/captains-deck",
    component: () =>
      import(/* webpackChunkName: "93939" */ "../pages/CaptainsDeck"),
  },
  {
    path: "/403",
    component: () => import(/* webpackChunkName: "UnAuth" */ "../pages/UnAuth"),
  },
  { path: "*", component: error }, //must be last
];

//https://router.vuejs.org/guide/essentials/navigation.html
