import BillingApi from "../generated/api/BillingApi";
import SubscriptionChangeRequest from "../generated/model/SubscriptionChangeRequest.js";

class BillingService {
  constructor() {
    this.BillingApi = new BillingApi();
  }

  async CreateCheckoutSession(checkoutSessionRequest) {
    return await window.$A.HandleApiResponse(
      this.BillingApi.createCheckoutSession(checkoutSessionRequest)
    )
  }

  async CreateCustomerPortalSession() {
    return await window.$A.HandleApiResponse(
      this.BillingApi.createCustomerPortalSession(null)
    )
  }

  async GetCurrentSubscription() {
    return await window.$A.HandleApiResponse(
      this.BillingApi.getCurrentSubscription(null),
      "",
      true
    );
  }

  async GetCurrentSubscriptionState() {
    return await window.$A.HandleApiResponse(
      this.BillingApi.getCurrentSubscriptionState(null),
      "",
      true
    );
  }

  async SetCurrentCardViaToken(cardToken) {
    return await window.$A.HandleApiResponse(
      this.BillingApi.setCurrentCardViaToken(cardToken),
      "",
      true
    );
  }

  async UpdateSubscription(sku, couponCode) {
    return await window.$A.HandleApiResponse(
      this.BillingApi.updateSubscription({
        subscriptionChangeRequest: SubscriptionChangeRequest.constructFromObject(
          {
            planSku: sku,
            couponCode: couponCode,
          }
        ),
      }),
      "Your Subscription Has Been Updated"
    );
  }

  async CancelSubscription() {
    return await window.$A.HandleApiResponse(
      this.BillingApi.cancelSubscription(null),
      "",
      true
    );
  }

  async GetProrate(sku) {
    return await window.$A.HandleApiResponse(
      this.BillingApi.getProrateIfUpgradeOrDowngrade(sku),
      "",
      true
    );
  }
  async HasActivePaymentMethod() {
    return await window.$A.HandleApiResponse(
      this.BillingApi.hasActivePaymentMethod(null),
      "",
      true
    );
  }
  async GetAllInvoices() {
    let invoices = await window.$A.HandleApiResponse(
      this.BillingApi.getInvoices(null),
      "",
      true
    );
    return !Array.isArray(invoices) ? [] : invoices;
  }
}

export default BillingService;
