/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CollectionMethod from './CollectionMethod';
import Product from './Product';
import SubscriptionState from './SubscriptionState';

/**
 * The Subscription model module.
 * @module model/Subscription
 * @version V1
 */
class Subscription {
    /**
     * Constructs a new <code>Subscription</code>.
     * @alias module:model/Subscription
     */
    constructor() { 
        
        Subscription.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Subscription</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Subscription} obj Optional instance to populate.
     * @return {module:model/Subscription} The populated <code>Subscription</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Subscription();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('planSku')) {
                obj['planSku'] = ApiClient.convertToType(data['planSku'], 'String');
            }
            if (data.hasOwnProperty('usingCoupon')) {
                obj['usingCoupon'] = ApiClient.convertToType(data['usingCoupon'], 'String');
            }
            if (data.hasOwnProperty('nextInvoiceTotal')) {
                obj['nextInvoiceTotal'] = ApiClient.convertToType(data['nextInvoiceTotal'], 'Number');
            }
            if (data.hasOwnProperty('nextChargeDate')) {
                obj['nextChargeDate'] = ApiClient.convertToType(data['nextChargeDate'], 'Date');
            }
            if (data.hasOwnProperty('billingCycleAnchor')) {
                obj['billingCycleAnchor'] = ApiClient.convertToType(data['billingCycleAnchor'], 'Date');
            }
            if (data.hasOwnProperty('currentPeriodStart')) {
                obj['currentPeriodStart'] = ApiClient.convertToType(data['currentPeriodStart'], 'Date');
            }
            if (data.hasOwnProperty('currentPeriodEnd')) {
                obj['currentPeriodEnd'] = ApiClient.convertToType(data['currentPeriodEnd'], 'Date');
            }
            if (data.hasOwnProperty('cancelAtPeriodEnd')) {
                obj['cancelAtPeriodEnd'] = ApiClient.convertToType(data['cancelAtPeriodEnd'], 'Boolean');
            }
            if (data.hasOwnProperty('cancelAt')) {
                obj['cancelAt'] = ApiClient.convertToType(data['cancelAt'], 'Date');
            }
            if (data.hasOwnProperty('canceledAt')) {
                obj['canceledAt'] = ApiClient.convertToType(data['canceledAt'], 'Date');
            }
            if (data.hasOwnProperty('previousSubId')) {
                obj['previousSubId'] = ApiClient.convertToType(data['previousSubId'], 'Number');
            }
            if (data.hasOwnProperty('previousSku')) {
                obj['previousSku'] = ApiClient.convertToType(data['previousSku'], 'String');
            }
            if (data.hasOwnProperty('collectionMethod')) {
                obj['collectionMethod'] = CollectionMethod.constructFromObject(data['collectionMethod']);
            }
            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('additionalSkus')) {
                obj['additionalSkus'] = ApiClient.convertToType(data['additionalSkus'], {'String': 'String'});
            }
            if (data.hasOwnProperty('hasActivePaymentMethod')) {
                obj['hasActivePaymentMethod'] = ApiClient.convertToType(data['hasActivePaymentMethod'], 'Boolean');
            }
            if (data.hasOwnProperty('subscriptionState')) {
                obj['subscriptionState'] = SubscriptionState.constructFromObject(data['subscriptionState']);
            }
            if (data.hasOwnProperty('plan')) {
                obj['plan'] = Product.constructFromObject(data['plan']);
            }
            if (data.hasOwnProperty('upgradeOptions')) {
                obj['upgradeOptions'] = ApiClient.convertToType(data['upgradeOptions'], [Product]);
            }
            if (data.hasOwnProperty('downGradeOptions')) {
                obj['downGradeOptions'] = ApiClient.convertToType(data['downGradeOptions'], [Product]);
            }
            if (data.hasOwnProperty('quantity')) {
                obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Subscription</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Subscription</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['planSku'] && !(typeof data['planSku'] === 'string' || data['planSku'] instanceof String)) {
            throw new Error("Expected the field `planSku` to be a primitive type in the JSON string but got " + data['planSku']);
        }
        // ensure the json data is a string
        if (data['usingCoupon'] && !(typeof data['usingCoupon'] === 'string' || data['usingCoupon'] instanceof String)) {
            throw new Error("Expected the field `usingCoupon` to be a primitive type in the JSON string but got " + data['usingCoupon']);
        }
        // ensure the json data is a string
        if (data['previousSku'] && !(typeof data['previousSku'] === 'string' || data['previousSku'] instanceof String)) {
            throw new Error("Expected the field `previousSku` to be a primitive type in the JSON string but got " + data['previousSku']);
        }
        // ensure the json data is a string
        if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
            throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
        }
        // validate the optional field `subscriptionState`
        if (data['subscriptionState']) { // data not null
          SubscriptionState.validateJSON(data['subscriptionState']);
        }
        // validate the optional field `plan`
        if (data['plan']) { // data not null
          Product.validateJSON(data['plan']);
        }
        if (data['upgradeOptions']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['upgradeOptions'])) {
                throw new Error("Expected the field `upgradeOptions` to be an array in the JSON data but got " + data['upgradeOptions']);
            }
            // validate the optional field `upgradeOptions` (array)
            for (const item of data['upgradeOptions']) {
                Product.validateJSON(item);
            };
        }
        if (data['downGradeOptions']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['downGradeOptions'])) {
                throw new Error("Expected the field `downGradeOptions` to be an array in the JSON data but got " + data['downGradeOptions']);
            }
            // validate the optional field `downGradeOptions` (array)
            for (const item of data['downGradeOptions']) {
                Product.validateJSON(item);
            };
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
Subscription.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
Subscription.prototype['accountId'] = undefined;

/**
 * @member {String} planSku
 */
Subscription.prototype['planSku'] = undefined;

/**
 * @member {String} usingCoupon
 */
Subscription.prototype['usingCoupon'] = undefined;

/**
 * @member {Number} nextInvoiceTotal
 */
Subscription.prototype['nextInvoiceTotal'] = undefined;

/**
 * @member {Date} nextChargeDate
 */
Subscription.prototype['nextChargeDate'] = undefined;

/**
 * @member {Date} billingCycleAnchor
 */
Subscription.prototype['billingCycleAnchor'] = undefined;

/**
 * @member {Date} currentPeriodStart
 */
Subscription.prototype['currentPeriodStart'] = undefined;

/**
 * @member {Date} currentPeriodEnd
 */
Subscription.prototype['currentPeriodEnd'] = undefined;

/**
 * @member {Boolean} cancelAtPeriodEnd
 */
Subscription.prototype['cancelAtPeriodEnd'] = undefined;

/**
 * @member {Date} cancelAt
 */
Subscription.prototype['cancelAt'] = undefined;

/**
 * @member {Date} canceledAt
 */
Subscription.prototype['canceledAt'] = undefined;

/**
 * @member {Number} previousSubId
 */
Subscription.prototype['previousSubId'] = undefined;

/**
 * @member {String} previousSku
 */
Subscription.prototype['previousSku'] = undefined;

/**
 * @member {module:model/CollectionMethod} collectionMethod
 */
Subscription.prototype['collectionMethod'] = undefined;

/**
 * @member {Boolean} active
 */
Subscription.prototype['active'] = undefined;

/**
 * @member {String} status
 */
Subscription.prototype['status'] = undefined;

/**
 * @member {Date} createdAt
 */
Subscription.prototype['createdAt'] = undefined;

/**
 * @member {Object.<String, String>} additionalSkus
 */
Subscription.prototype['additionalSkus'] = undefined;

/**
 * @member {Boolean} hasActivePaymentMethod
 */
Subscription.prototype['hasActivePaymentMethod'] = undefined;

/**
 * @member {module:model/SubscriptionState} subscriptionState
 */
Subscription.prototype['subscriptionState'] = undefined;

/**
 * @member {module:model/Product} plan
 */
Subscription.prototype['plan'] = undefined;

/**
 * @member {Array.<module:model/Product>} upgradeOptions
 */
Subscription.prototype['upgradeOptions'] = undefined;

/**
 * @member {Array.<module:model/Product>} downGradeOptions
 */
Subscription.prototype['downGradeOptions'] = undefined;

/**
 * @member {Number} quantity
 */
Subscription.prototype['quantity'] = undefined;






export default Subscription;

