/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PreflightUploadRequest model module.
 * @module model/PreflightUploadRequest
 * @version V1
 */
class PreflightUploadRequest {
    /**
     * Constructs a new <code>PreflightUploadRequest</code>.
     * @alias module:model/PreflightUploadRequest
     */
    constructor() { 
        
        PreflightUploadRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PreflightUploadRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PreflightUploadRequest} obj Optional instance to populate.
     * @return {module:model/PreflightUploadRequest} The populated <code>PreflightUploadRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PreflightUploadRequest();

            if (data.hasOwnProperty('fileName')) {
                obj['fileName'] = ApiClient.convertToType(data['fileName'], 'String');
            }
            if (data.hasOwnProperty('heightInches')) {
                obj['heightInches'] = ApiClient.convertToType(data['heightInches'], 'Number');
            }
            if (data.hasOwnProperty('widthInches')) {
                obj['widthInches'] = ApiClient.convertToType(data['widthInches'], 'Number');
            }
            if (data.hasOwnProperty('bleedInches')) {
                obj['bleedInches'] = ApiClient.convertToType(data['bleedInches'], 'Number');
            }
            if (data.hasOwnProperty('colorProfile')) {
                obj['colorProfile'] = ApiClient.convertToType(data['colorProfile'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PreflightUploadRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PreflightUploadRequest</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['fileName'] && !(typeof data['fileName'] === 'string' || data['fileName'] instanceof String)) {
            throw new Error("Expected the field `fileName` to be a primitive type in the JSON string but got " + data['fileName']);
        }
        // ensure the json data is a string
        if (data['colorProfile'] && !(typeof data['colorProfile'] === 'string' || data['colorProfile'] instanceof String)) {
            throw new Error("Expected the field `colorProfile` to be a primitive type in the JSON string but got " + data['colorProfile']);
        }

        return true;
    }


}



/**
 * @member {String} fileName
 */
PreflightUploadRequest.prototype['fileName'] = undefined;

/**
 * @member {Number} heightInches
 */
PreflightUploadRequest.prototype['heightInches'] = undefined;

/**
 * @member {Number} widthInches
 */
PreflightUploadRequest.prototype['widthInches'] = undefined;

/**
 * @member {Number} bleedInches
 */
PreflightUploadRequest.prototype['bleedInches'] = undefined;

/**
 * @member {String} colorProfile
 */
PreflightUploadRequest.prototype['colorProfile'] = undefined;






export default PreflightUploadRequest;

