/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PreSignedUrlResponse model module.
 * @module model/PreSignedUrlResponse
 * @version V1
 */
class PreSignedUrlResponse {
    /**
     * Constructs a new <code>PreSignedUrlResponse</code>.
     * @alias module:model/PreSignedUrlResponse
     */
    constructor() { 
        
        PreSignedUrlResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PreSignedUrlResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PreSignedUrlResponse} obj Optional instance to populate.
     * @return {module:model/PreSignedUrlResponse} The populated <code>PreSignedUrlResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PreSignedUrlResponse();

            if (data.hasOwnProperty('uploadUrl')) {
                obj['uploadUrl'] = ApiClient.convertToType(data['uploadUrl'], 'String');
            }
            if (data.hasOwnProperty('s3Key')) {
                obj['s3Key'] = ApiClient.convertToType(data['s3Key'], 'String');
            }
            if (data.hasOwnProperty('preflightId')) {
                obj['preflightId'] = ApiClient.convertToType(data['preflightId'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PreSignedUrlResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PreSignedUrlResponse</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['uploadUrl'] && !(typeof data['uploadUrl'] === 'string' || data['uploadUrl'] instanceof String)) {
            throw new Error("Expected the field `uploadUrl` to be a primitive type in the JSON string but got " + data['uploadUrl']);
        }
        // ensure the json data is a string
        if (data['s3Key'] && !(typeof data['s3Key'] === 'string' || data['s3Key'] instanceof String)) {
            throw new Error("Expected the field `s3Key` to be a primitive type in the JSON string but got " + data['s3Key']);
        }

        return true;
    }


}



/**
 * @member {String} uploadUrl
 */
PreSignedUrlResponse.prototype['uploadUrl'] = undefined;

/**
 * @member {String} s3Key
 */
PreSignedUrlResponse.prototype['s3Key'] = undefined;

/**
 * @member {Number} preflightId
 */
PreSignedUrlResponse.prototype['preflightId'] = undefined;






export default PreSignedUrlResponse;

