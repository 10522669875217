/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Preflight model module.
 * @module model/Preflight
 * @version V1
 */
class Preflight {
    /**
     * Constructs a new <code>Preflight</code>.
     * @alias module:model/Preflight
     */
    constructor() { 
        
        Preflight.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Preflight</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Preflight} obj Optional instance to populate.
     * @return {module:model/Preflight} The populated <code>Preflight</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Preflight();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'Number');
            }
            if (data.hasOwnProperty('s3Key')) {
                obj['s3Key'] = ApiClient.convertToType(data['s3Key'], 'String');
            }
            if (data.hasOwnProperty('processedS3Key')) {
                obj['processedS3Key'] = ApiClient.convertToType(data['processedS3Key'], 'String');
            }
            if (data.hasOwnProperty('fileName')) {
                obj['fileName'] = ApiClient.convertToType(data['fileName'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'Date');
            }
            if (data.hasOwnProperty('queuedAt')) {
                obj['queuedAt'] = ApiClient.convertToType(data['queuedAt'], 'Date');
            }
            if (data.hasOwnProperty('isDeleted')) {
                obj['isDeleted'] = ApiClient.convertToType(data['isDeleted'], 'Boolean');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('error')) {
                obj['error'] = ApiClient.convertToType(data['error'], 'String');
            }
            if (data.hasOwnProperty('heightInches')) {
                obj['heightInches'] = ApiClient.convertToType(data['heightInches'], 'Number');
            }
            if (data.hasOwnProperty('widthInches')) {
                obj['widthInches'] = ApiClient.convertToType(data['widthInches'], 'Number');
            }
            if (data.hasOwnProperty('bleedInches')) {
                obj['bleedInches'] = ApiClient.convertToType(data['bleedInches'], 'Number');
            }
            if (data.hasOwnProperty('colorProfile')) {
                obj['colorProfile'] = ApiClient.convertToType(data['colorProfile'], 'String');
            }
            if (data.hasOwnProperty('callbackToken')) {
                obj['callbackToken'] = ApiClient.convertToType(data['callbackToken'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Preflight</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Preflight</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['s3Key'] && !(typeof data['s3Key'] === 'string' || data['s3Key'] instanceof String)) {
            throw new Error("Expected the field `s3Key` to be a primitive type in the JSON string but got " + data['s3Key']);
        }
        // ensure the json data is a string
        if (data['processedS3Key'] && !(typeof data['processedS3Key'] === 'string' || data['processedS3Key'] instanceof String)) {
            throw new Error("Expected the field `processedS3Key` to be a primitive type in the JSON string but got " + data['processedS3Key']);
        }
        // ensure the json data is a string
        if (data['fileName'] && !(typeof data['fileName'] === 'string' || data['fileName'] instanceof String)) {
            throw new Error("Expected the field `fileName` to be a primitive type in the JSON string but got " + data['fileName']);
        }
        // ensure the json data is a string
        if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
            throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
        }
        // ensure the json data is a string
        if (data['error'] && !(typeof data['error'] === 'string' || data['error'] instanceof String)) {
            throw new Error("Expected the field `error` to be a primitive type in the JSON string but got " + data['error']);
        }
        // ensure the json data is a string
        if (data['colorProfile'] && !(typeof data['colorProfile'] === 'string' || data['colorProfile'] instanceof String)) {
            throw new Error("Expected the field `colorProfile` to be a primitive type in the JSON string but got " + data['colorProfile']);
        }
        // ensure the json data is a string
        if (data['callbackToken'] && !(typeof data['callbackToken'] === 'string' || data['callbackToken'] instanceof String)) {
            throw new Error("Expected the field `callbackToken` to be a primitive type in the JSON string but got " + data['callbackToken']);
        }

        return true;
    }


}



/**
 * @member {Number} id
 */
Preflight.prototype['id'] = undefined;

/**
 * @member {Number} accountId
 */
Preflight.prototype['accountId'] = undefined;

/**
 * @member {String} s3Key
 */
Preflight.prototype['s3Key'] = undefined;

/**
 * @member {String} processedS3Key
 */
Preflight.prototype['processedS3Key'] = undefined;

/**
 * @member {String} fileName
 */
Preflight.prototype['fileName'] = undefined;

/**
 * @member {Date} createdAt
 */
Preflight.prototype['createdAt'] = undefined;

/**
 * @member {Date} queuedAt
 */
Preflight.prototype['queuedAt'] = undefined;

/**
 * @member {Boolean} isDeleted
 */
Preflight.prototype['isDeleted'] = undefined;

/**
 * @member {String} status
 */
Preflight.prototype['status'] = undefined;

/**
 * @member {String} error
 */
Preflight.prototype['error'] = undefined;

/**
 * @member {Number} heightInches
 */
Preflight.prototype['heightInches'] = undefined;

/**
 * @member {Number} widthInches
 */
Preflight.prototype['widthInches'] = undefined;

/**
 * @member {Number} bleedInches
 */
Preflight.prototype['bleedInches'] = undefined;

/**
 * @member {String} colorProfile
 */
Preflight.prototype['colorProfile'] = undefined;

/**
 * @member {String} callbackToken
 */
Preflight.prototype['callbackToken'] = undefined;






export default Preflight;

