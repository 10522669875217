/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The StripeCheckoutSessionRequest model module.
 * @module model/StripeCheckoutSessionRequest
 * @version V1
 */
class StripeCheckoutSessionRequest {
    /**
     * Constructs a new <code>StripeCheckoutSessionRequest</code>.
     * @alias module:model/StripeCheckoutSessionRequest
     */
    constructor() { 
        
        StripeCheckoutSessionRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>StripeCheckoutSessionRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StripeCheckoutSessionRequest} obj Optional instance to populate.
     * @return {module:model/StripeCheckoutSessionRequest} The populated <code>StripeCheckoutSessionRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StripeCheckoutSessionRequest();

            if (data.hasOwnProperty('mainSku')) {
                obj['mainSku'] = ApiClient.convertToType(data['mainSku'], 'String');
            }
            if (data.hasOwnProperty('additionalSkus')) {
                obj['additionalSkus'] = ApiClient.convertToType(data['additionalSkus'], {'String': 'Number'});
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>StripeCheckoutSessionRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>StripeCheckoutSessionRequest</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['mainSku'] && !(typeof data['mainSku'] === 'string' || data['mainSku'] instanceof String)) {
            throw new Error("Expected the field `mainSku` to be a primitive type in the JSON string but got " + data['mainSku']);
        }

        return true;
    }


}



/**
 * @member {String} mainSku
 */
StripeCheckoutSessionRequest.prototype['mainSku'] = undefined;

/**
 * @member {Object.<String, Number>} additionalSkus
 */
StripeCheckoutSessionRequest.prototype['additionalSkus'] = undefined;






export default StripeCheckoutSessionRequest;

