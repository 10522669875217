/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import HttpStatusCode from './HttpStatusCode';

/**
 * The AshoreException model module.
 * @module model/AshoreException
 * @version V1
 */
class AshoreException {
    /**
     * Constructs a new <code>AshoreException</code>.
     * @alias module:model/AshoreException
     */
    constructor() { 
        
        AshoreException.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AshoreException</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AshoreException} obj Optional instance to populate.
     * @return {module:model/AshoreException} The populated <code>AshoreException</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AshoreException();

            if (data.hasOwnProperty('outCode')) {
                obj['outCode'] = HttpStatusCode.constructFromObject(data['outCode']);
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('data')) {
                obj['data'] = ApiClient.convertToType(data['data'], {'String': Object});
            }
            if (data.hasOwnProperty('innerException')) {
                obj['innerException'] = ApiClient.convertToType(data['innerException'], Object);
            }
            if (data.hasOwnProperty('helpLink')) {
                obj['helpLink'] = ApiClient.convertToType(data['helpLink'], 'String');
            }
            if (data.hasOwnProperty('source')) {
                obj['source'] = ApiClient.convertToType(data['source'], 'String');
            }
            if (data.hasOwnProperty('hResult')) {
                obj['hResult'] = ApiClient.convertToType(data['hResult'], 'Number');
            }
            if (data.hasOwnProperty('stackTrace')) {
                obj['stackTrace'] = ApiClient.convertToType(data['stackTrace'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AshoreException</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AshoreException</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['message'] && !(typeof data['message'] === 'string' || data['message'] instanceof String)) {
            throw new Error("Expected the field `message` to be a primitive type in the JSON string but got " + data['message']);
        }
        // ensure the json data is a string
        if (data['helpLink'] && !(typeof data['helpLink'] === 'string' || data['helpLink'] instanceof String)) {
            throw new Error("Expected the field `helpLink` to be a primitive type in the JSON string but got " + data['helpLink']);
        }
        // ensure the json data is a string
        if (data['source'] && !(typeof data['source'] === 'string' || data['source'] instanceof String)) {
            throw new Error("Expected the field `source` to be a primitive type in the JSON string but got " + data['source']);
        }
        // ensure the json data is a string
        if (data['stackTrace'] && !(typeof data['stackTrace'] === 'string' || data['stackTrace'] instanceof String)) {
            throw new Error("Expected the field `stackTrace` to be a primitive type in the JSON string but got " + data['stackTrace']);
        }

        return true;
    }


}



/**
 * @member {module:model/HttpStatusCode} outCode
 */
AshoreException.prototype['outCode'] = undefined;

/**
 * @member {String} message
 */
AshoreException.prototype['message'] = undefined;

/**
 * @member {Object.<String, Object>} data
 */
AshoreException.prototype['data'] = undefined;

/**
 * @member {Object} innerException
 */
AshoreException.prototype['innerException'] = undefined;

/**
 * @member {String} helpLink
 */
AshoreException.prototype['helpLink'] = undefined;

/**
 * @member {String} source
 */
AshoreException.prototype['source'] = undefined;

/**
 * @member {Number} hResult
 */
AshoreException.prototype['hResult'] = undefined;

/**
 * @member {String} stackTrace
 */
AshoreException.prototype['stackTrace'] = undefined;






export default AshoreException;

