/**
 * Ashore API
 * All Endpoints to control your ashore account
 *
 * The version of the OpenAPI document: V1
 * Contact: info@ashoreapp.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ColorProfileGroup from './ColorProfileGroup';

/**
 * The ColorProfileResponse model module.
 * @module model/ColorProfileResponse
 * @version V1
 */
class ColorProfileResponse {
    /**
     * Constructs a new <code>ColorProfileResponse</code>.
     * @alias module:model/ColorProfileResponse
     */
    constructor() { 
        
        ColorProfileResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ColorProfileResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ColorProfileResponse} obj Optional instance to populate.
     * @return {module:model/ColorProfileResponse} The populated <code>ColorProfileResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ColorProfileResponse();

            if (data.hasOwnProperty('rgb')) {
                obj['rgb'] = ColorProfileGroup.constructFromObject(data['rgb']);
            }
            if (data.hasOwnProperty('cmyk')) {
                obj['cmyk'] = ColorProfileGroup.constructFromObject(data['cmyk']);
            }
            if (data.hasOwnProperty('defaultProfile')) {
                obj['defaultProfile'] = ApiClient.convertToType(data['defaultProfile'], 'String');
            }
            if (data.hasOwnProperty('defaultRGB')) {
                obj['defaultRGB'] = ApiClient.convertToType(data['defaultRGB'], 'String');
            }
            if (data.hasOwnProperty('defaultCMYK')) {
                obj['defaultCMYK'] = ApiClient.convertToType(data['defaultCMYK'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ColorProfileResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ColorProfileResponse</code>.
     */
    static validateJSON(data) {
        // validate the optional field `rgb`
        if (data['rgb']) { // data not null
          ColorProfileGroup.validateJSON(data['rgb']);
        }
        // validate the optional field `cmyk`
        if (data['cmyk']) { // data not null
          ColorProfileGroup.validateJSON(data['cmyk']);
        }
        // ensure the json data is a string
        if (data['defaultProfile'] && !(typeof data['defaultProfile'] === 'string' || data['defaultProfile'] instanceof String)) {
            throw new Error("Expected the field `defaultProfile` to be a primitive type in the JSON string but got " + data['defaultProfile']);
        }
        // ensure the json data is a string
        if (data['defaultRGB'] && !(typeof data['defaultRGB'] === 'string' || data['defaultRGB'] instanceof String)) {
            throw new Error("Expected the field `defaultRGB` to be a primitive type in the JSON string but got " + data['defaultRGB']);
        }
        // ensure the json data is a string
        if (data['defaultCMYK'] && !(typeof data['defaultCMYK'] === 'string' || data['defaultCMYK'] instanceof String)) {
            throw new Error("Expected the field `defaultCMYK` to be a primitive type in the JSON string but got " + data['defaultCMYK']);
        }

        return true;
    }


}



/**
 * @member {module:model/ColorProfileGroup} rgb
 */
ColorProfileResponse.prototype['rgb'] = undefined;

/**
 * @member {module:model/ColorProfileGroup} cmyk
 */
ColorProfileResponse.prototype['cmyk'] = undefined;

/**
 * @member {String} defaultProfile
 */
ColorProfileResponse.prototype['defaultProfile'] = undefined;

/**
 * @member {String} defaultRGB
 */
ColorProfileResponse.prototype['defaultRGB'] = undefined;

/**
 * @member {String} defaultCMYK
 */
ColorProfileResponse.prototype['defaultCMYK'] = undefined;






export default ColorProfileResponse;

